.footerSala{

    font-family: 'Montserrat';
    font-size: 16px;
    height: auto;
    background-color: white;
}

.footerSala__row{

    margin-top: 20px;
    padding-bottom: 80px;
}

.imagen__footer{

    width: 350px;
}

.footerSala__horario{

    padding-top: 25px;
    text-align: center
}

.horario__text{

    font-weight: 800;
    font-size: 40px;
    color: #050566;
}

.footerSala__contacto{

    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
}

.contacto{

    display: flex;
    align-items: center;
    margin-right: 20px;
}

.contacto__iconoCirculo{

    position: relative;
    display: flex;
    align-items: center;
}

.contacto__circulo{

    width: 33px;
    height: 33px;
    margin-right: 10px;
    border: 2px solid #050566;
    border-radius: 100%;

}

.contacto__iconoTEL{
    
    position: absolute;
    right: 18px;
    color: #050566;
}

.contacto__iconoWA{

    font-size: 35px;
    margin-right: 10px;
    color: #050566;
}

.contacto__number{

    font-size: 16px;
    font-weight: 700;
    color: #050566;
    
}


/*XS*/ 
@media (max-width: 575px) {

    .footerSala__row{

        margin-top: 20px;
        padding-bottom: 20px;
    }

    .footerSala__imagen{

        text-align: center;
    }

    .imagen__footer{

        width: 300px;
    }

    .footerSala__horario{

        padding-top: 25px;
        text-align: center;
    }

    .horario__text{

        font-size: 35px;
        margin-left: 10px;
    }

    .footerSala__contacto{

        justify-content: space-evenly;
    }

    .contacto{

        margin-right: 0;
    }

    .contacto__circulo{

        width: 22px;
        height: 22px;
        margin-right: 10px;
    }

    .contacto__iconoTEL{

        right: 16px;
        font-size: 10px;
    }
    
    .contacto__number{

        font-size: 16px;
    }

    .contacto__iconoWA{

        font-size: 24px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .footerSala__row{

        margin-top: 40px;
        padding-bottom: 40px;
    }

    .footerSala__imagen{

        text-align: center;
    }

    .imagen__footer{

        width: 335px;
    }

    .footerSala__horario{

        padding-top: 25px;
        text-align: center;
    }

    .horario__text{

        font-size: 40px;
        margin-left: 10px;
    }

    .footerSala__contacto{

        justify-content: space-evenly;
    }

    .contacto{

        margin-right: 0;
    }

    .contacto__circulo{

        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .contacto__iconoTEL{

        right: 17px;
        font-size: 12px;
    }
    
    .contacto__number{

        font-size: 20px;
    }

    .contacto__iconoWA{

        font-size: 27px;
    }

}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .imagen__footer{

        width: 100%;
    }

    .footerSala__horario{

        padding-top: 12px;
        text-align: center;
    }

    .horario__text{

        font-size: 25px;
    }

    .footerSala__contacto{

        margin-top: 12px;
    }

    .contacto{

        margin-right: 0;
    }

    .contacto__circulo{

        width: 20px;
        height: 20px;
        margin-right: 7px;
    }

    .contacto__iconoTEL{

        right: 12px;
        font-size: 10px;
    }

    .contacto__number{

        font-size: 12px;
    }

    .contacto__iconoWA{

        font-size: 20px;
        margin-right: 7px;
        margin-left: 7px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .imagen__footer{

        width: 275px;
    }

    .footerSala__horario{

        padding-top: 20px;
    }

    .horario__text{

        font-size: 35px;
    }

    .footerSala__contacto{

        margin-top: 18px;
    }

    .contacto{

        margin-right: 0;
    }

    .contacto__circulo{

        width: 23px;
        height: 23px;
        border: 2px solid #050566;
    }

    .contacto__iconoTEL{

        right: 16px;
        font-size: 11px;
    }

    .contacto__number{

        font-size: 16px;
        margin-right: 0;
    }

    .contacto__iconoWA{

        font-size: 25px;
        margin-right: 10px;
        margin-left: 10px;
    }

}


/*XL*/
@media (max-width: 1399px) and (min-width: 1200px){
  
}