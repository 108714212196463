@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dongle&family=Fjalla+One&family=Montserrat:wght@300;400;500;600;700;900&display=swap');

:root{
  --colorNegro: #202020;
  --colorBlanco: #fcfcff;
  --colorRojo: #F65F5D;
  --colorRojohover: #d64441;
  --colorAzul:#419895;
  --colorAzulFuerte: #2F4056;
  --colorTexto: #3d3d3d;
  --colorWA: #075e54;
  --colorWAhover: #064b43;
  --colorAmarillo: #F9BB60;
  --colorAzulHospitelec: #29AAE3;
  --colorAzulFuerteHospitelec: #093548;
}

body{
  font-family: 'Montserrat';
}

a{
  text-decoration: none;
}

p{
  margin: 0;
  list-style: 15px
}

span{

  line-height: 20px;
}

button:focus{

  outline: none;
}

.btnPrimario{

  width: 100%;
  border-radius: 16px;
  background-color: var(--colorRojo);
  border: none;
  transition: ease 0.5s all;
  color: var(--colorBlanco);
}

.btnPrimario:hover{

  background-color: var(--colorRojohover);
}

.noPadding{
  padding: 0!important;
}

.noMargin{
  margin: 0!important;
}

.flag{
  font-size: 16px;
  height: 1.1875em;
  font-family: 'Bebas Neue', cursive;
}

.flag__texto{
  background-color: var(--colorRojo);
  color: var(--colorBlanco);
  font-size: 1em;
  padding: 2px 10px;
  line-height: 17px;
}

.flag__texto-azul{
  background-color: var(--colorAzul);
  color: var(--colorBlanco);
  font-size: 1em;
  padding: 2px 10px;
  line-height: 17px;
}

.flag__texto-rojo{
  background-color: var(--colorRojo);
}

.hidden{
  opacity: 0;
}

.content-select select{

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#room{
  height: 100vh;
  width: 100%;
}

.hide{
  display: none;
}

.bg-azulHospitelec{
  background-color: var(--colorAzulHospitelec);
}


/*XS*/ 
@media (max-width: 575px) {

  .col-xs-0{

      display: none;
  }
  
  .noPadding-xs{
      padding: 0!important;
  }

  .noMargin-xs{
      margin: 0!important;
  }
}
/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

  .col-sm-0{
      display: none;
  }

  .noPadding-sm{
      padding: 0!important;
  }

  .noMargin-sm{
      margin: 0!important;
  }
  
}
/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

  .col-md-0{

      display: none;
  }

  .noPadding-md{
      padding: 0!important;
  }

  .noMargin-md{
      margin: 0!important;
  }
  
}
/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {
  
  .col-lg-0{

      display: none;
  }

  .noPadding-lg{
      padding: 0!important;
  }

  .noMargin-lg{
      margin: 0!important;
  }
  
}
/*XL*/
@media (min-width: 1200px){

  .col-xl-0{

      display: none;
  }

  .noPadding-xl{
      padding: 0!important;
  }

  .noMargin-xl{
      margin: 0!important;
  }
  
}


/* CODIGO PARA EL SPINNER DE ESPERA */

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--colorRojo);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: var(--colorRojo);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
