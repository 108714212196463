.saludProfesional{

    width: 100%;
    height: 550px;
    background: linear-gradient(90deg, rgba(41, 170, 227, 0) 14.77%, var(--colorAzulHospitelec) 57.53%);
    display: flex;
    align-items: center;  
    padding-left: 100px;
    padding-right: 100px;  
}

.saludProfesional__row{

    display: flex;
    align-items: center;
}

.img__telemedicina{

    width: 500px;
    /* height: 350px; */
    border-radius: 20px;
    object-fit: cover;
}

.saludProfesional__titulo{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 40px;
    color: var(--colorBlanco);
    margin-bottom: 25px;
}

.saludProfesional__parrafo{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #B2DDED;
    margin-bottom: 35px;
}

.saludProfesional__button{

    width: 260px;
    height: 52px;
    background-color: var(--colorBlanco);
    border-radius: 50px;
    border: transparent;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #224E61;
}

/*XS*/ 
@media (max-width: 575px) {

    .saludProfesional{

        height: auto;
        background-color: var(--colorAzulHospitelec);
        padding-left: 12px;
        padding-right: 12px;
    }

    .img__telemedicina{

        width: 100%;
        height: 100%;
        margin-top: 30px;
    }

    .saludProfesional__titulo{

        font-size: 30px;
        text-align: center;
        margin-top: 30px;
    }

    .saludProfesional__parrafo{

        font-size: 14px;
        text-align: center;
    }

    .saludProfesional__boton{

        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .saludProfesional__button{

        width: 225px;
        height: 40px;
        font-size: 12px;
        font-weight: 700;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {


    .saludProfesional{

        padding-left: 12px;
        padding-right: 12px;
        height: 450px;
    }

    .saludProfesional__img{

        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .img__telemedicina{

        width: 85%;
        height: 90%;
        object-fit: cover;
    }


    .saludProfesional{

        height: auto;
        background-color: var(--colorAzulHospitelec);
    }

    .saludProfesional__titulo{

        font-size: 25px;
        text-align: center;
        margin-top: 30px;
    }

    .saludProfesional__parrafo{

        font-size: 12px;
        text-align: center;
    }

    .saludProfesional__boton{

        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    .saludProfesional__button{

        width: 225px;
        height: 40px;
        font-size: 12px;
        font-weight: 700;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .saludProfesional{

        padding-left: 0;
        padding-right: 0;
        height: 450px;
    }

    .img__telemedicina{

        width: 330px;
        height: 300px;
        object-fit: cover;
    }

    .saludProfesional__titulo{

        font-size: 28px;

    }

    .saludProfesional__parrafo{

        font-size: 14px;
    }

    .saludProfesional__button{

        width: 230px;
        height: 45px;
        font-size: 14px;
        font-weight: 700;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .saludProfesional{

        padding-left: 0;
        padding-right: 0; 
    }

    .img__telemedicina{

        width: 450px;
        object-fit: cover;
    }
    
    .saludProfesional__button{

        font-weight: 700;
    }
}


/*XL*/
@media (min-width: 1200px){
  
}