#gracias{
    display:flex;
    flex-direction:column;
    align-items:center;
    height: 100vh;
    justify-content: center;
    background-color: var(--colorAzulHospitelec);
}

#gracias h1{
    font-size: 3.5em;
    font-weight: bold;
    color: var(--colorBlanco);
    text-align: center;
    margin-bottom: 1em;
}

#gracias h2{
    color: var(--colorBlanco);
    text-align: center;
    font-size: 1.5em;
}

#gracias p, #gracias span{
    font-size: 1.5em;
    color: var(--colorBlanco);
    font-weight: bold;
}

#gracias textarea{
    height: 150px;
    font-family: 'Montserrat';
    padding: 10px;
    width: 50vw;
}

#gracias textarea:hover{
    background-color: white;
}

#gracias form{
    width: 100%;
    text-align: center;
}

#gracias form button{
    margin-top: 20px;
}

.react-rater-star.is-active, .react-rater-star.will-be-active{
    color: var(--colorAmarillo);
}

.react-rater-star{
    font-size: 3.2em;
}

.btn-whatsapp{
    background-color: #25cc64;
    color:white;
    width:160px;
    border-radius: 50px;
    margin:10px
}

/*XS*/ 
@media (max-width: 575px) {

    #gracias{
        font-size: 11px;
    }

    #gracias textarea{
        width: 100%;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {
    
    #gracias{
        font-size: 12px;
    }

    #gracias textarea{
        width: 100%;
    }

}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    #gracias{
        font-size: 14px;
    }

}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    
}


/*XL*/
@media (min-width: 1200px){
  

}

