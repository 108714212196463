.tiempoEspera{

    font-size: 16px;
    background-color: var(--colorBlanco);
    height: auto;
}

.infoSala__inicio{

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoSala__titulo{

    color: gray;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 150px;
}

.infoSala__titulo1{

    color: var(--colorAzulHospitelec);
    line-height: 45px;
}

.infoSala__corazon{

    margin-right: 150px;
}

.infoSala__img{

    width: 100%;
    height: 100%;
}

.infoSala__textTp{

    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.textTp__1{

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 25px;
    color: gray;
}

.textTp__2{

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 45px;
    color: #29AAE3;
}

.textTp__3{

    font-weight: 700;
    font-size: 20px;
    color: #29AAE3;
}

/*XS*/ 
@media (max-width: 575px) {

    .col-xs-0{

        display: none;
    }

    .infoSala__titulo{

        font-size: 30px;
        margin-left: 35px;
    }

    .infoSala__corazon{

        margin-right: 35px;
    }

    .infoSala__textTp{

        justify-content: space-around;
        margin-bottom: 10px;
    }

    .textTp__1{

        font-size: 14px;
    }

    .textTp__2{

        font-size: 20px;
    }

    .textTp__3{

        font-size: 14px;
    }

    .infoSala__textTp2{

        text-align: center;
        margin-bottom: 15px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .col-sm-0{

        display: none;
    }

    .infoSala__titulo{

        font-size: 30px;
        margin-left: 50px;
    }

    .infoSala__corazon{

        margin-right: 50px;
    }

    .infoSala__textTp{

        justify-content: space-around;
        margin-bottom: 10px;
    }

    .textTp__1{

        font-size: 14px;
    }

    .textTp__2{

        font-size: 25px;
    }

    .textTp__3{

        font-size: 14px;
    }

    .infoSala__textTp2{

        text-align: center;
        margin-bottom: 15px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .col-md-0{

        display: none;
    }

    .infoSala__titulo{

        font-size: 40px;
        margin-left: 75px;
    }

    .infoSala__corazon{

        margin-right: 80px;
    }

    .textTp__1{

        font-size: 16px;
    }

    .textTp__2{

        font-size: 35px;
    }

    .textTp__3{

        font-size: 14px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .col-lg-0{

        display: none;
    }

    .infoSala__titulo{

        font-size: 50px;
        margin-left: 110px;
    }

    .infoSala__corazon{

        margin-right: 125px;
    }

    .infoSala__textTp{

        justify-content: space-around;
    }

    .textTp__1{

        font-size: 22px;
    }

    .textTp__2{

        font-size: 43px;
    }

    .textTp__3{

        font-size: 17px;
    }

}


/*XL*/
@media (min-width: 1200px){

    .col-xl-0{

        display: none;
    }
  
}