.cabezoteHospitelecCompu{

    font-size: 16px;
    background-color: var(--colorAzulHospitelec);
    height: 85px;
}

.cabezoteHospitelecCompu__escitorio{

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoHospitelec{

    width: 320px;
    height: auto;
    margin-top: 11px;
    margin-bottom: 7px;
}

.escritorio__lista{
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin:0;
}

.escritorio__lista li{

    display: flex;
}

.referencia__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--colorBlanco);
    margin-left: 20px;
    
}

.referencia__text:hover{

    color: var(--colorAzulFuerteHospitelec);
    text-decoration: none;
}

.referencia__button{
    width: 266px;
    height: 45px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: var(--colorAzulHospitelec);
    margin-top: 8px;
    margin-left: 20px;

    /* TEXTO */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--colorBlanco);
    /* ******** */
}

.referencia__button:hover{
    background-color: var(--colorBlanco);
    /* TEXTO */
    color: var(--colorAzulHospitelec);
    /* ******** */
}

/*XS*/ 
@media (max-width: 575px) {

    .cabezoteHospitelecCompu{

        height: 45px;
    }

    .cabezoteHospitelecCompu__escitorio{

        display: block;
    }

    .escritorio__logoHospitelec{

        text-align: center;
    }


    .logoHospitelec{

        width: 220px;
        height: auto;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .cabezoteHospitelecCompu{

        height: 45px;
    }

    .cabezoteHospitelecCompu__escitorio{

        display: block;
    }

    .escritorio__logoHospitelec{

        text-align: center;
    }


    .logoHospitelec{

        width: 220px;
        height: auto;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .col-md-0{

        display: none;
    }

    .noMargin-md{
        margin: 0!important;
    }

    .logoHospitelec{

        width: 220px;
        height: auto;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .cabezoteHospitelecCompu{

        font-size: 16px;
        background-color: var(--colorAzulHospitelec);
        height: 80px;
    }

    .logoHospitelec{

        width: 240px;
        height: auto;
    }

    
}


/*XL*/
@media (min-width: 1200px){
  
}

