#footer{
    display: flex;
    color:white;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    padding: 2rem 0rem;
}

#footer img{
    width: 250px;
}

#footer p{
    text-align: right;
    width: 60vw;
}

#iconsFooter{
    font-size: 3rem;
    color:white;
    justify-content: center;
}

#iconsFooter a{
    color:white
}

#iconsFooter a:hover{
    color: #f2f2f2;
}

#siguenosRedesSociales{
    padding-bottom: 20px;
}

.btn.btn-white{
    background-color: white;
    color: black;
    border-radius: 12px;
}

.btn.btn-white:hover{
    background-color: #f5f5f5;
    color: black;
}


/*XS*/ 
@media (max-width: 575px) {

    #footer{
        font-size: 1rem;
    }
    
    #footer img{
        width: 180px;
    }
    
    #iconsFooter{
        font-size: 2rem;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {
    
    #footer{
        font-size: 1rem;
    }
    
    #footer img{
        width: 200px;
    }
    
    #iconsFooter{
        font-size: 2rem;
    }

}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    #footer{
        font-size: 1.2rem;
    }
    
    #footer img{
        width: 200px;
    }
    
    #iconsFooter{
        font-size: 2rem;
    }

}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    
}


/*XL*/
@media (min-width: 1200px){
  

}

