.cabezoteHospitelec{

    font-size: 16px;
    background-color: #29AAE3;
    width: 100%;
}

.cabezoteHospitelec__logo{

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 0;
}

.logo__hospitelec{

    width: 250px;
    background-color: #29AAE3;

}

/*XS*/ 
@media (max-width: 575px) {

    .logo__hospitelec{

        width: 200px;
        height: auto;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .logo__hospitelec{

        width: 200px;
        height: auto;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

}


/*XL*/
@media (min-width: 1200px){
  
}