.nuestrosDoctores{

    font-size: 16px;
    height: auto;
    background-color: var(--colorBlanco);
    padding-bottom: 50px;
    margin-top: 4rem;
}

.nuestrosDoctores__titulo{

    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: var(--colorAzulFuerteHospitelec);
}

.card__nuestrosDoctores{

    background: var(--colorBlanco);
    border: 1px solid #B2DDED;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    width: 100%;
    height: 400px;
}

.header__nuestrosDoctores{
    position: relative;
}

.nuestrosDoctores__img{

    width: 165px!important;
    height: 165px;
    border-radius: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.nuestrosDoctores__icon{

    font-size: 29px;
    color: var(--colorAzulHospitelec);
    position: absolute;
    right: 140px;
    top: 137px;
}

.body__nuestrosDoctores{

    text-align: center;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.nuestrosDoctores__nombreDoctor{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: var(--colorAzulHospitelec);

}

.nuestrosDoctores__nombreEspecialidad{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--colorAzulFuerteHospitelec);
}

.nuestrosDoctores__añosExperiencia{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #4D4D4D;

}

.button__tele{

    width: 200px;
    height: 45px;
    border: 1px solid var(--colorAzulHospitelec);
    box-sizing: border-box;
    border-radius: 75px;
    background-color: var(--colorBlanco);
    margin-top: 25px;
    margin-bottom: 100px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--colorAzulHospitelec);
}

.button__tele:hover{
    background-color: var(--colorAzulHospitelec);
    color: var(--colorBlanco);
    
}

.doctores__text:hover{

    color: var(--colorAzulFuerteHospitelec);
    text-decoration: none;
}

.doctores__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #1B71A1;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    
}

/*XS*/ 
@media (max-width: 575px) {

    .nuestrosDoctores__titulo{

        font-size: 40px;
    }

    .card__nuestrosDoctores{

        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .nuestrosDoctores__img{

        width: 165px;
        height: 165px;
        margin-top: 60px;
    }

    .nuestrosDoctores__icon{

        font-size: 25px;
        right: 127px;
        top: 140px;
    }

    .nuestrosDoctores__nombreDoctor{

        font-size: 20px;
    }

    .nuestrosDoctores__nombreEspecialidad{

        font-size: 16px;
    }

    .nuestrosDoctores__añosExperiencia{

        font-size: 13px;
    }

    .button__tele{

        width: 180px;
        height: 40px;
        font-size: 12px;
        margin-bottom: 80px;
    }

    .doctores__text{

        text-align: center;
        font-size: 20px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .nuestrosDoctores__titulo{

        font-size: 40px;
    }

    .card__nuestrosDoctores{

        width: 62%;
        margin-left: auto;
        margin-right: auto;
    }

    .nuestrosDoctores__img{

        width: 165px;
        height: 165px;
        margin-top: 60px;
    }

    .nuestrosDoctores__icon{

        font-size: 25px;
        right: 103px;
        top: 140px;
    }

    .nuestrosDoctores__nombreDoctor{

        font-size: 20px;
    }

    .nuestrosDoctores__nombreEspecialidad{

        font-size: 16px;
    }

    .nuestrosDoctores__añosExperiencia{

        font-size: 13px;
    }

    .button__tele{

        width: 180px;
        height: 40px;
        font-size: 12px;
        margin-bottom: 80px;
    }

    .doctores__text{

        text-align: center;
        font-size: 20px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .nuestrosDoctores__titulo{

        font-size: 45px;
    }

    .nuestrosDoctores__img{

        width: 150px;
        height: 150px;
        margin-top: 60px;
    }

    .nuestrosDoctores__icon{

        font-size: 25px;
        color: var(--colorAzulHospitelec);
        position: absolute;
        right: 115px;
        top: 130px;
    }

    .nuestrosDoctores__nombreDoctor{

        font-size: 20px;
    }

    .nuestrosDoctores__nombreEspecialidad{

        font-size: 16px;
    }

    .nuestrosDoctores__añosExperiencia{

        font-size: 14px;
    }

    .button__tele{

        width: 185px;
        height: 40px;
        font-size: 14px ;
        margin-bottom: 80px;
    }

    .doctores__text{

        font-size: 22px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .nuestrosDoctores__img{

        width: 145px;
        height: 145px;
        margin-top: 65px;
    }

    .nuestrosDoctores__icon{

        font-size: 25px;
        color: var(--colorAzulHospitelec);
        position: absolute;
        right: 95px;
        top: 125px;
    }

    .body__nuestrosDoctores{

        text-align: center;
        margin-top: 20px;
    }

    .nuestrosDoctores__nombreDoctor{

        font-size: 22px;
    }
    
    .nuestrosDoctores__nombreEspecialidad{
    
        font-size: 18px;
    }
    
}


/*XL*/
@media (min-width: 1200px){
  
}