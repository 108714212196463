.cardPrivado{

    background: #F9F9F9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.header__cardPrivado{

    display: flex;
    justify-content: center;
    padding-top: 25px;
    
}

.cardPrivado__img{

    width: 234px;
    height: 94px;
    margin-left: auto;
    margin-right: auto;

}

.cardPrivado__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 81px;
    
}