.pagPrinHospitelec{

    font-size: 16px;
    background-image: url("../../assets/img/fondo_inicio.svg");
    background-repeat: no-repeat;
    height: 600px;
    background-position: 0 -250px;

}

.introduccion__paginaPrincipal{

    margin-top: 80px;
}

.text__claro{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 2rem;
    line-height: 50px;
    color: var(--colorAzulFuerteHospitelec);
}

.text__fuerte{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 50px;
    color: var(--colorAzulFuerteHospitelec);
}

.text__parrafo{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--colorBlanco);
}

.button__teleorientacion{

    width: 323px;
    height: 52px;
    border-radius: 50px;
    background-color: var(--colorAzulFuerteHospitelec);
    border: transparent;
    margin-top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--colorBlanco);
}

.introduccion__img{

    margin-top: 20px;
    position: absolute;
}

.img__paginaPrincipal{

    width: 540px;
    height: 500px;
    border-radius: 75%;
    object-fit: contain;
}

/*XS*/ 
@media (max-width: 575px) {

    .introduccion__img{
        position: relative;
        text-align: center;
    }

    .img__paginaPrincipal{
            
        width: 250px;
        height: 250px;
    }

    .pagPrinHospitelec{

        background-repeat: no-repeat;
        height: 500px;
        background-position:-270px -375px;
    }

    .introduccion__paginaPrincipal{
        margin-top: 50px;
    }

    .text__claro{

        font-size: 1.6rem;
        line-height:3rem;
        text-align: center;
    }

    .text__fuerte{

        font-size: 1.6rem;
        line-height:3rem;
    }

    .text__parrafo{

        font-size: 16px;
        text-align: center;
    }

    .paginaPrincipal__button{

        display: flex;
        justify-content: center;
    }

    .button__teleorientacion{

        width: 250px;
        height: 45px;
        font-size: 12px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .introduccion__img{
        position: relative;
        text-align: center;
    }

    .img__paginaPrincipal{
            
        width: 300px;
        height: 300px;
    }

    .pagPrinHospitelec{

        background-repeat: no-repeat;
        height: 500px;
        background-position:-270px -375px;
    }

    .introduccion__paginaPrincipal{
        margin-top: 50px;
    }

    .text__claro{

        font-size: 1.6rem;
        line-height:3rem;
        text-align: center;
    }

    .text__fuerte{

        font-size: 1.6rem;
        line-height:3rem;
    }

    .text__parrafo{

        font-size: 16px;
        text-align: center;
    }

    .paginaPrincipal__button{

        display: flex;
        justify-content: center;
    }

    .button__teleorientacion{

        width: 250px;
        height: 45px;
        font-size: 12px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .pagPrinHospitelec{

        background-image: url("../../assets/img/fondo_inicio.svg");
        background-repeat: no-repeat;
        height: 450px;
        background-position: -100px -370px;
    
    }

    .introduccion__paginaPrincipal{

        margin-top: 40px;
    }

    .text__claro{

        font-size: 1.7rem;
        line-height:3rem;
    }

    .text__fuerte{

        font-size: 1.7rem;
        line-height:3rem;
    }

    .text__parrafo{

        font-size: 16px;
    }

    .button__teleorientacion{

        width: 275px;
        height: 45px;
        font-size: 14px;
    }

    .introduccion__img{

        margin-top: 60px;
    }

    .img__paginaPrincipal{

        width: 330px;
        height: 320px;
        
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .pagPrinHospitelec{

        height: 510px;
        background-position: 0 -280px;
    }

    .text__claro{

        font-size: 2rem;
        line-height:3rem;
        
    }
    
    .text__fuerte{
    
        font-size: 2rem;
        line-height:3rem;
        
    }

    .text__parrafo{

        font-size: 18px;
    }

    .button__teleorientacion{

        width: 300px;
        height: 52px;
    
        font-size: 16px;
    }

    .img__paginaPrincipal{

        width: 480px;
        height: 500px;

    }

    
}


/*XL*/
@media (min-width: 1200px){
  
}