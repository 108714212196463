.nuestrasEspecialidades{

    font-size: 16px;
    background-color: var(--colorBlanco);
    height: auto;
    padding-bottom: 30px;
    margin-top: 4rem;
}

.titulo__especialidades{

    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    color: var(--colorAzulFuerteHospitelec);
    text-align: center;
}

.especialidades{

    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
}

.especialidades__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: gray;
}

.neurologia__img{

    margin-bottom: 10px;
    width: 100px;
    height: 100px;
}

.rehabilitacion__img{

    margin-bottom: 10px;
    width: 100px;
    height: 100px;
}

.medicoGeneral__img{

    margin-bottom: 10px;
    width: 100px;
    height: 100px;
}

.cardiologo__img{

    margin-bottom: 10px;
    margin-left: 10px;
    width: 100px;
    height: 100px;
}

.odontologia__img{

    
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
}

/*XS*/ 
@media (max-width: 575px) {

    .titulo__especialidades{
        margin-top: 12rem;
        font-size: 40px;
    }

    .especialidades{

        display: inline;
        text-align: center;
    }

    .especialidades__text{

        font-size: 15px;
        margin-bottom: 30px;
    }

    .neurologia__img{

        margin-bottom: 5px;
        margin-right: 7px;
        width: 125px;
        height: 125px;
    }

    .rehabilitacion__img{

        margin-right: 14px;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .medicoGeneral__img{

        margin-right: 14px;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .cardiologo__img{

        margin-left: 12px;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .odontologia__img{

        margin-left: 0;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .titulo__especialidades{
        margin-top: 10rem;
        font-size: 40px;
    }

    .especialidades{

        text-align: center;
    }

    .especialidades__text{

        font-size: 15px;
        margin-bottom: 30px;
    }

    .neurologia__img{

        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .rehabilitacion__img{

        margin-left: 0;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .medicoGeneral__img{

        margin-left: 0;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .cardiologo__img{

        margin-left: 0;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }

    .odontologia__img{

        margin-left: 0;
        margin-bottom: 5px;
        width: 125px;
        height: 125px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .titulo__especialidades{

        font-size: 45px;
        margin-top: 5rem;
    }

    .especialidades{

        text-align: center;
        margin-top: 30px;
    }

    .neurologia__img{

        width: 125px;
        height: 125px;
    }

    .rehabilitacion__img{

        width: 125px;
        height: 125px;     
        margin-right: 10px;   
    }

    .medicoGeneral__img{

        width: 125px;
        height: 125px;
        margin-right: 12px;
    }

    .cardiologo__img{

        width: 125px;
        height: 125px;
        margin-left: 10px;
    }

    .odontologia__img{

        width: 125px;
        height: 125px;
        margin-left: 0;
    }

    .especialidades__text{

        font-size: 16px;
        margin-bottom: 25px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    
}


/*XL*/
@media (min-width: 1200px){
  
}