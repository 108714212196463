.comoFunciona{

    font-size: 16px;
    padding-top: 100px;
    background-color: var(--colorBlanco);
    height: auto;
}

.comoFunciona__titulo{

    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: var(--colorAzulFuerteHospitelec);
}

.comoFunciona__row{

    margin-top: 50px;
    padding-bottom: 100px;

}

.comoFunciona__container{
    width: 100%;
    padding: 0 1rem;
}

.pasos__img{

    width: 250px;
    height: 250px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.pasos__info{

    display: flex;
    align-items: center;
    margin-top: 35px;
}

.info__numero{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 98px;
    color: var(--colorAzulHospitelec);
}

.info__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: var(--colorNegro);
    margin-left: 10px;
    line-height: 25px;
    text-align: justify;
}

/*XS*/ 
@media (max-width: 575px) {

    .comoFunciona__titulo{

        font-size: 40px;
    }

    .pasos__img{

        width: 200px;
        height: 200px;
    }

    .pasos__info{

        align-items: center;
    }

    .info__numero{

        font-size: 40px;
    }

    .info__text{

        font-size: 14px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .comoFunciona__titulo{

        font-size: 40px;
    }

    .pasos__img{

        width: 200px;
        height: 200px;
    }

    .pasos__info{

        align-items: center;
        justify-content: center;
    }

    .info__numero{

        font-size: 40px;
    }

    .info__text{

        font-size: 14px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .comoFunciona__titulo{

        font-size: 45px;
    }

    .pasos__img{

        width: 200px;
        height: 200px;

    }

    .pasos__info{

        margin-top: 20px;
        align-items: center;
    }

    .info__numero{

        font-size: 45px;
    }

    .info__text{

        font-size: 14px;
        line-height: 22px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .pasos__img{

        width: 200px;
        height: 200px;
    }

    .info__numero{

        font-size: 50px;
    }

    .info__text{

        font-size: 16px;
        line-height: 25px;
    }
    
}


/*XL*/
@media (min-width: 1200px){
  
}