.salaEspera{

    font-size: 16px;
    padding-right: 15px;
    padding-left: 15px;
}

.salaEspera__titulo{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    text-align: center;
    color: var(--colorNegro);
    margin-top: 30px;
    margin-bottom: 3px;
}

.salaEspera__info{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: var(--colorNegro);
    padding-right: 20px;
    padding-left: 20px;
}

.card__hospitelec{

    width: 228px;
    height: auto;
    background: #F9F9F9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 15px;
    padding-top: 27px;
    padding-left: 15px;
    padding-bottom: 15px;

    display: flex;
    justify-content: flex-start;
    
}

.cardHospitelec__img{

    width: 68px;
    height: 68px;
    border-radius: 100%;
    object-fit: cover;
}

.cardHospitelec__infoDoc{

    margin-left: 15px;
    
}

.infoDoc__name{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--colorNegro);

}

.infoDoc__profesion{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--colorNegro);
}

.cardHospitelec__button{

    width: 80%;
    height: 23px;
    background: #093548;
    border-radius: 20px;
    border: transparent;
    margin-left: 15px;
    margin-top: 26px;
    
}

.buttonOcupado__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--colorBlanco);
}

.vistaPrevia{

    margin-top: 15px;

}

.vistaPrevia__img{

    width: 96px;
    height: 96px;
    margin-left: 15px;
    border: 3px solid #29AAE3;
    object-fit: cover;
}

.vistaPrevia__text{

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    width: 104px;
    margin-left: 11px;
    margin-top: 10px;
}
