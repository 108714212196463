.teleorientacion{

    font-size: 16px;
    background-color: var(--colorBlanco);
    height: auto;
    padding-bottom: 30px;
}

.titulo__teleorientacion{

    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: var(--colorAzulFuerteHospitelec);
    margin-top: 100px;
    margin-bottom: 30px;
}

.teleorientacion__imagen{

    width: 95%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.teleorientacion__img{

    width: 550px;
    height: 350px;
    border-radius: 40px;
    object-fit: cover;
    margin-top: 18px;
}

.datos__input{

    width: 100%;
    height: 60px;
    border: 2px solid var(--colorAzulHospitelec);
    box-sizing: border-box;
    border-radius: 20px;
    padding-left: 10px;
    margin-top: 20px;
}

.datos__input:hover{

    background: rgba(41, 170, 227, 0.1);
}

.teleorientacion__servicio{

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: var(--colorBlanco);
    border: 2px solid var(--colorAzulHospitelec);
    box-sizing: border-box;
    border-radius: 20px;
    padding-left: 10px;
    color: gray;
    margin-top: 20px;

}

.teleorientacion__icono{

    position: absolute;
    right: 20px;
    display: block;
    top: calc(50% - 9px);
    color: gray;
}

.content-select{

    position: relative;

}

.servicio__text{

    color: gray;
}

.servicio__icon{

    color: gray;
}

.datos__input__caja{

    width: 100%;
    height: 200px;
    border: 2px solid var(--colorAzulHospitelec);
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 20px;
}

.teleorientacion__button{

    background: var(--colorAzulHospitelec);
    border-radius: 50px;
    width: 100%;
    height: 40px;
    border: transparent;
    margin-top: 15px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--colorBlanco);
}

/*XS*/ 
@media (max-width: 575px) {

    .titulo__teleorientacion{

        font-size: 40px;
        margin-top: 0;
    }

    .teleorientacion__imagen{

        width: 100%;
        height: 250px;
        display: block;
        margin-top: 0;
    }

    .teleorientacion__img{

        width: 100%;
        height: 100%;
        margin-top: 0;
    }

    .datos__input{

        font-size: 12px;
        height: 50px;
        margin-top: 20px;
    }

    .teleorientacion__servicio{

        height: 50px;
        font-size: 12px;
        margin-top: 20px;
    }

    .teleorientacion__icono{

        font-size: 12px;
    }

    .datos__input__caja{

        height: 150px;
        font-size: 12px;
        margin-top: 20px;
    }

    .teleorientacion__button{

        height: 35px;
        font-size: 12px;
        margin-top: 12px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .titulo__teleorientacion{

        font-size: 40px;
        margin-top: 0;
    }

    .teleorientacion__imagen{

        width: 100%;
        height: 250px;
        display: block;
        margin-top: 0;
    }

    .teleorientacion__img{

        width: 100%;
        height: 100%;
        margin-top: 0;
    }

    .datos__input{

        font-size: 12px;
        height: 50px;
        margin-top: 20px;
    }

    .teleorientacion__servicio{

        height: 50px;
        font-size: 12px;
        margin-top: 20px;
    }

    .teleorientacion__icono{

        font-size: 12px;
    }

    .datos__input__caja{

        height: 150px;
        font-size: 12px;
        margin-top: 20px;
    }

    .teleorientacion__button{

        height: 35px;
        font-size: 12px;
        margin-top: 12px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .titulo__teleorientacion{

        font-size: 45px;
        margin-top: 100px;
        margin-bottom: 30px;
    }

    .teleorientacion__imagen{

        width: 100%;
        height: 100%;
        display: block;
        margin-top: 0;
    }

    .teleorientacion__img{

        width: 100%;
        height: 350px;
        border-radius: 40px;
        object-fit: cover;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .datos__input{

        height: 50px;
        font-size: 14px;
        margin-top: 10px;
    }

    .teleorientacion__servicio{

        height: 50px;
        font-size: 14px;
        margin-top: 10px;
    }

    .teleorientacion__icono{

        font-size: 14px;
    }

    .datos__input__caja{

        height: 150px;
        font-size: 14px;
        margin-top: 10px;

    }

    .teleorientacion__button{

        height: 35px;
        font-size: 14px;
        margin-top: 15px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .teleorientacion__imagen{

        width: 100%;
        display: flex;
        justify-content: flex-start;
        
    }

    .teleorientacion__img{

        width: 450px;
        margin-top: 20px;
    }

    .datos__input{

        margin-top: 20px;
    }

    .teleorientacion__servicio{

        margin-top: 20px;
    }

    .datos__input__caja{

        margin-top: 20px;
    }

    .teleorientacion__button{

        margin-top: 15px;
    }
    
}


/*XL*/
@media (min-width: 1200px){
  
}