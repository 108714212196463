.slider{

    background-color: #050566;
    font-size: 'Montserrat';
    height: auto;
}

.sliderHospitelec{

    margin-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    height: 100%;
}

.sliderHospitelec__logoHP{

    width: 200px;
}

.sliderHospitelec__infoEspecialidad{

    display: flex;
    align-items: baseline;
    position: relative;
    margin-top: 150px;
    height: auto;
}

.infoEspecialidad__junior{

    width: 375px;
    height: 185px;
}

.infoEspecialidad__kid{

    width: 300px;
    height: 400px;
    position: absolute;
    right: 10px;
    bottom: -10px;
}

.sliderHospitelec__precio{

    font-weight: 800;
    font-size: 50px;
    color: aqua;
    display: flex;
    justify-content: end;
    padding-top: 90px;
    margin-right: 35px;
}

.sliderHospitelec__card{

    margin-top: 20px;
}

.headerCard{

    width: 95%;
    height: 70px;
    border: 2px solid white;
    background-color: white;
    border-radius: 5px;
    padding-top: 20px;
    padding-left: 40px;
    margin-left: auto;
    margin-right: auto;
}

.headerCard__text{

    font-weight: 700;
    font-size: 40px;
    color: #080874;
}

.bodyCard{

    width: 93%;
    height: auto;
    border: 2px white dashed;
    border-top: none;
    margin-left: auto;
    margin-right: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.bodyCard__lista{

    padding-top: 20px;
}

.bodyCard__lista li{

    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.lista__iconoJunior{

    width: 15px;
    height: 3px;
    background-color: white;
    margin-right: 10px;
}

.lista__servicioJunior{

    color: white;
    font-size: 30px;
    font-weight: 600;
}


/*XS*/ 
@media (max-width: 575px) {

    .sliderHospitelec{

        margin-top: 25px;
        padding-bottom: 25px;
    }

    .sliderHospitelec__logoHP{

        width: 125px;
    }

    .sliderHospitelec__infoEspecialidad{

        margin-top: 140px;
    }

    .infoEspecialidad__junior{

        width: 210px;
        height: auto;
        object-fit: contain;
    }

    .infoEspecialidad__kid{

        width: 240px;
        height: auto;
        right: 0;
    }

    .sliderHospitelec__precio{

        font-size: 30px;
        padding-top: 10px;
        margin-right: 35px;
    }

    .sliderHospitelec__card{

        margin-top: 15px;
    }    

    .headerCard{

        width: 85%;
        height: 50px;
        padding-top: 13px;
        padding-left: 35px;
    }

    .headerCard__text{

        font-size: 25px;
    }

    .bodyCard{

        width: 83%;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .bodyCard__lista{

        padding-top: 10px;
    }

    .lista__iconoJunior{

        width: 10px;
        height: 2px;
    }

    .lista__servicioJunior{

        font-size: 18px;
    }

}


/*SM*/
@media (max-width: 767px) and (min-width: 576px) {

    .sliderHospitelec{

        margin-top: 35px;
        padding-bottom: 35px;
    }

    .sliderHospitelec__logoHP{

        width: 160px;
    }

    .sliderHospitelec__infoEspecialidad{

        margin-top: 140px;
    }

    .infoEspecialidad__junior{

        width: 285px;
        height: auto;
        object-fit: contain;
    }

    .infoEspecialidad__kid{

        width: 285px;
        height: auto;
        right: 0;
    }

    .sliderHospitelec__precio{

        font-size: 35px;
        padding-top: 10px;
        margin-right: 35px;
    }

    .sliderHospitelec__card{

        margin-top: 15px;
    }    

    .headerCard{

        width: 90%;
        height: 50px;
        padding-top: 13px;
        padding-left: 35px;
    }

    .headerCard__text{

        font-size: 30px;
    }

    .bodyCard{

        width: 88%;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .bodyCard__lista{

        padding-top: 10px;
    }

    .lista__iconoJunior{

        width: 10px;
        height: 2px;
    }

    .lista__servicioJunior{

        font-size: 25px;
    }
    
}


/*MD*/
@media (max-width: 991px) and (min-width: 768px) {

    .sliderHospitelec{

        margin-top: 35px;
        padding-bottom: 35px;
    }

    .sliderHospitelec__logoHP{

        width: 150px;
    }

    .sliderHospitelec__infoEspecialidad{

        margin-top: 140px;
    }

    .infoEspecialidad__junior{

        width: 235px;
        height: 135px;
        object-fit: contain;
    }

    .infoEspecialidad__kid{

        width: 250px;
        height: 335px;
        right: -60px;
    }

    .sliderHospitelec__precio{

        font-size: 35px;
        padding-top: 70px;
        margin-right: 15px;
    }

    .headerCard{

        width: 85%;
        height: 50px;
        padding-top: 12px;
        padding-left: 35px;
        margin-right: 0;
    }

    .headerCard__text{

        font-size: 25px;
    }

    .bodyCard{

        width: 83%;
        margin-right: 3px;
        padding-bottom: 0;
    }

    .bodyCard__lista{

        padding-top: 0;
    }

    .lista__iconoJunior{

        width: 10px;
        height: 2px;
    }

    .lista__servicioJunior{

        font-size: 20px;
    }
    
}


/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    .sliderHospitelec__logoHP{

        width: 175px;
    }

    .infoEspecialidad__junior{

        width: 290px;
        height: 165px;
        object-fit: contain;
    }

    .infoEspecialidad__kid{

        width: 275px;
        right: -50px;
    }

    .sliderHospitelec__precio{

        padding-top: 75px;
        margin-right: 15px;
    }

    .headerCard{

        width: 90%;
        margin-right: 0;
    }

    .headerCard__text{

        font-size: 35px;
    }

    .bodyCard{

        width: 88%;
        margin-right: 5px;
    }

    .lista__iconoJunior{

        width: 10px;
        height: 2px;
    }

    .lista__servicioJunior{

        font-size: 25px;
    }

}


/*XL*/
@media (max-width: 1399px) and (min-width: 1200px){

    .slider{

        background-color: #050566;
        font-size: 'Montserrat';
        height: auto;
    }
    
    .sliderHospitelec{
    
        margin-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        height: 100%;
    }
    
    .sliderHospitelec__logoHP{
    
        width: 200px;
    }
    
    .sliderHospitelec__infoEspecialidad{
    
        display: flex;
        align-items: baseline;
        position: relative;
        margin-top: 150px;
        height: auto;
    }
    
    .infoEspecialidad__junior{
    
        width: 375px;
        height: 185px;
        object-fit: contain;
    }
    
    .infoEspecialidad__kid{
    
        width: 300px;
        height: 400px;
        position: absolute;
        right: -30px;
        bottom: -10px;
    }
    
    .sliderHospitelec__precio{
    
        font-weight: 800;
        font-size: 50px;
        color: aqua;
        display: flex;
        justify-content: end;
        padding-top: 90px;
        margin-right: 35px;
    }
    
    .sliderHospitelec__card{
    
        margin-top: 20px;
    }
    
    .headerCard{
    
        width: 95%;
        height: 70px;
        border: 2px solid white;
        background-color: white;
        border-radius: 5px;
        padding-top: 20px;
        padding-left: 40px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .headerCard__text{
    
        font-weight: 700;
        font-size: 40px;
        color: #080874;
    }
    
    .bodyCard{
    
        width: 93%;
        height: auto;
        border: 2px white dashed;
        border-top: none;
        margin-left: auto;
        margin-right: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    .bodyCard__lista{
    
        padding-top: 20px;
    }
    
    .bodyCard__lista li{
    
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    
    .lista__iconoJunior{
    
        width: 15px;
        height: 3px;
        background-color: white;
        margin-right: 10px;
    }
    
    .lista__servicioJunior{
    
        color: white;
        font-size: 30px;
        font-weight: 600;
    }
  
}